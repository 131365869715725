<template>
  <cyclic-payment #default="{
    unitInfo,
    isLoading,
    selectedBrickOption,
    selectedMonthsCount,
    changeSelectedMonthCount,
    brickToSubscribeCount,
    isOneTimePayment,
    changeIsOneTimePayment,
    changeSelectedBrickOption,
    isVolunteerCode,
    changeIsVolunteerCode,
    startPayment,
    backToUnitPage,
    paymentForm,
  }">
    <div
        class="app-container"
        v-loading.fullscreen="isLoading"
        element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-row class="row-card">
        <el-col :lg="{span: 18, offset: 3}">
          <el-card>
            <h2 class="heading page-heading">
              {{ $t(`page_family_payment.title`) }}
            </h2>
            <div class="unit-info">
              <div class="d-flex align-center unit-info__item">
                <v-icon color="#606266" name="home"/>
                <span>
                  {{ $t('page_family_payment.card_info.unit_title') }}
              </span>
                <span>
                  &nbsp;-&nbsp;
              </span>
                <span class="font-semi-bold">
                  {{ unitInfo.unitTitle }}
              </span>
              </div>
              <UnitProgressRenderless :progress="unitInfo.supportedChildren" :total="unitInfo.children">
                <template #default="{progressDifference}">
                  <div class="d-flex align-center unit-info__item">
                    <v-icon name="heart_broken">
                      <svg v-svg symbol="adoption_icon" style="fill: #606266"/>
                    </v-icon>
                    <span>
                      {{ $t('page_family_payment.card_info.unit_bricks_difference') }}
                  </span>
                    <span>
                      &nbsp;-&nbsp;
                  </span>
                    <span class="font-semi-bold">
                      {{ formatMoneyLeak(progressDifference, unitInfo) }}
                  </span>
                  </div>
                </template>
              </UnitProgressRenderless>
            </div>
            <div class="mt-1">
              <el-radio-group :disabled="!isLoggedIn"
                              :value="isOneTimePayment"
              >
                <el-radio :label="false" @change="changeIsOneTimePayment(false)">
                  {{
                    isMd
                        ? $t('page_family_payment.payment_types.cyclic_payment_short')
                        : $t('page_family_payment.payment_types.cyclic_payment')
                  }}
                </el-radio>
                <el-radio :class="{ 'mt-1': isMd }" :label="true" @change="changeIsOneTimePayment(true)">
                  {{
                    isMd
                        ? $t('page_family_payment.payment_types.one_time_payment_short')
                        : $t('page_family_payment.payment_types.one_time_payment')
                  }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row class="row-card mt-1" v-if="!isLoggedIn">
        <el-col :lg="{span: 18, offset: 3}">
          <el-card>
            <div class="card-require-login">
              <img :src="$options.emptyStateImage" alt="empty_state">
              <h3 class="heading">
                {{ $t('page_family_payment.require_login.title') }}
              </h3>
              <p class="card-require-login__description">
                {{ $t('page_family_payment.require_login.description') }}
              </p>
              <div class="card-require-login__actions">
                <v-button type="primary" @click.prevent="$router.push({name: 'register'})">
                  {{ $t('page_family_payment.require_login.btn_register') }}
                </v-button>
                <v-button
                    @click="$router.push({
                name: 'login', params: {back: true}
                })"
                >
                  {{ $t('page_family_payment.require_login.btn_login') }}
                </v-button>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row class="row-card" v-if="isLoggedIn">
        <el-col :lg="{span: 18, offset: 3}">
          <el-card>
            <el-row>
              <el-col>
                <el-form-item :label="$t('page_family_payment.fields.radio_group_bricks')">
                  <div class="el-radio-group--grid">
                    <el-radio
                        v-for="(opt, idx) in $options.CHILDREN_COUNT_OPTIONS"
                        :key="idx"
                        :value="selectedBrickOption"
                        @change="changeSelectedBrickOption(opt)"
                        :label="opt" border
                    >{{ $tc('page_family_payment.fields.radio_bricks', opt) }}
                    </el-radio>
                    <el-radio
                        :value="selectedBrickOption"
                        @change="changeSelectedBrickOption($options.OTHER_CHILDREN_OPTION)"
                        :label="$options.OTHER_CHILDREN_OPTION" border
                    >{{ $t('page_family_payment.fields.radio_bricks_custom') }}
                    </el-radio>
                  </div>
                </el-form-item>
              </el-col>
              <el-col v-if="selectedBrickOption === $options.OTHER_CHILDREN_OPTION">
                <el-form-item
                    prop="customChildrenCount"
                    :label="$t('page_family_payment.fields.bricks')"
                >
                  <el-input v-model.number="paymentForm.customChildrenCount"/>
                </el-form-item>
              </el-col>
              <el-col v-if="isOneTimePayment">
                <el-form-item :label="$t('page_family_payment.fields.months')">
                  <div class="el-radio-group--grid">
                    <el-radio
                        v-for="(opt, idx) in $options.MONTHS_COUNT_OPTIONS"
                        :key="idx"
                        :value="selectedMonthsCount"
                        @change="changeSelectedMonthCount(opt.value)"
                        :label="opt.value" border
                    >
                      {{ $tc('page_family_payment.fields.radio_month', opt.pluralization, {count: opt.value}) }}
                    </el-radio>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <SubscriptionCalculator
                    :is-one-time-payment="isOneTimePayment"
                    :children-count="brickToSubscribeCount"
                    :months-count="selectedMonthsCount"
                    :monthly-cost="unitInfo.monthlyCost"
                    :unit-type="unitInfo.unitType"
                />
              </el-col>
            </el-row>
            <el-row v-if="isVolunteerCode">
              <el-col>
                <volunteer-code-input form-item-prop="volunteerCode"
                                      v-model="paymentForm.volunteerCode"
                />
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <div class="payment-card-actions">
                  <div>
                    <el-button type="primary"
                               @click="startPayment">
                      {{ $t('page_family_payment.btn_submit') }}
                    </el-button>
                    <el-button @click="backToUnitPage">
                      {{ $t('page_family_payment.btn_cancel') }}
                    </el-button>
                  </div>
                  <div>
                    <v-button-toggle ref="volunteerBtnRef"
                                     :value="isVolunteerCode"
                                     @click.native="changeIsVolunteerCode"
                    >
                      {{ $t('page_family_payment.btn_volunteer') }}
                    </v-button-toggle>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <PaymentProviderCard v-if="isLoggedIn"/>
    </div>
  </cyclic-payment>
</template>

<script>
import {mapGetters} from 'vuex'

import PaymentProviderCard from '../shared/components/PaymentProviderCard'

import emptyStateImage from '../../../assets/images/adoption_empty_state.svg'
import VButton from '../../components/ui/v-button/VButton'
import VIcon from '../../components/ui/v-icon/VIcon'
import UnitProgressRenderless from '../../client/components/units/unit-progress/UnitProgressRenderless'
import SubscriptionCalculator from '../shared/components/SubscriptionCalculator'

import VButtonToggle from '../../components/ui/v-button-toggle/VButtonToggle'
import VolunteerCodeInput from '../shared/components/volunteer-code-input/VolunteerCodeInput'

import {
  CHILDREN_COUNT_OPTIONS,
  OTHER_CHILDREN_OPTION,
  MONTHS_COUNT_OPTIONS
} from '../shared/constants/countOptions'
import CyclicPayment from '../cyclic-payment/CyclicPayment'

import {moneyFormat} from '../../shared/utils/moneyFormat'

export default {
  name: 'Family',
  components: {
    CyclicPayment,
    VolunteerCodeInput,
    VButtonToggle,
    SubscriptionCalculator,
    UnitProgressRenderless,
    VButton,
    PaymentProviderCard,
    VIcon
  },
  emptyStateImage,
  CHILDREN_COUNT_OPTIONS,
  OTHER_CHILDREN_OPTION,
  MONTHS_COUNT_OPTIONS,
  inject: ['responsive'],
  computed: {
    ...mapGetters({
      isLoggedIn: 'authState/isLoggedIn'
    }),
    isMd() {
      return this.responsive.isMd
    },
  },
  methods: {
    formatMoneyLeak(difference, unitInfo) {
      return `${moneyFormat(difference * unitInfo.monthlyCost)}/${this.$t('months_short')}`
    },
    maxAmountFormat(bricks, monthlyCost) {
      return moneyFormat(bricks * monthlyCost)
    }
  },
}
</script>

<style scoped lang="scss">
.card-require-login {
  text-align: center;

  &__description {
    max-width: 460px;
    margin: 0 auto;
  }

  &__actions {
    margin-top: 1.5em;
  }
}

.alert-row {
  margin-bottom: 1em;
}
</style>
